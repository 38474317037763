<template>
    <div class="Questionnaire">
        <div class="title">问卷调查</div>
        <div class="wid_100">
            <div
                class="problem"
                v-for="(item, index) in problemList"
                :key="'pro' + index"
            >
                <div class="mb-15">
                    {{
                        `Q${index + 1}、(${
                            subjectType[item.questions.type] || ""
                        }) ${item.questions.title || ""}`
                    }}
                </div>
                <div>
                    <!-- 单选 -->
                    <el-radio-group
                        v-model="subData[index].answer"
                        v-if="
                            item.questions.status == 0 ||
                            item.questions.status == 2
                        "
                        class="option ml-35"
                    >
                        <el-radio
                            :label="option.value"
                            v-for="(option, idx) in item.questions.status == 2
                                ? [
                                      { value: '1', title: '是' },
                                      { value: '0', title: '否' },
                                  ]
                                : item.questions.option"
                            :key="'opt' + idx"
                            class="option-card"
                            >{{ option.title }}</el-radio
                        >
                    </el-radio-group>
                    <!-- 多选 -->
                    <el-checkbox-group
                        class="group"
                        v-model="subData[index].checked"
                        v-if="item.questions.status == 1"
                    >
                        <el-checkbox
                            :label="check.value"
                            v-for="(check, checkindex) in item.questions.option"
                            :key="'check' + checkindex"
                            >{{ check.title }}</el-checkbox
                        >
                    </el-checkbox-group>
                    <!-- 填空 -->
                    <el-input
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="subData[index].answer"
                        maxlength="200"
                        :rows="3"
                        class="textarea"
                        show-word-limit
                        v-if="item.questions.status == 3"
                    >
                    </el-input>
                </div>
            </div>
        </div>
        <div class="submit click" @click="submit">提交</div>
        <EvaluateSuccess v-if="show"></EvaluateSuccess>
    </div>
</template>

<script>
    import EvaluateSuccess from "@/components/EvaluateSuccess.vue";
    export default {
        name: "Questionnaire",
        components: {
            EvaluateSuccess,
        },
        data() {
            return {
                problemList: [{ questions: { checked: "" } }], //问题列表
                problem: {}, //问卷对象
                id: 0, //问卷id
                show: false, //成功提示
                subData: [], //选择项
                subjectType: ["单选", "多选", "判断", "填空"],
            };
        },

        mounted() {
            this.id = Number(this.$route.query.id);
            this.$https
                .get("/api/exam/evaluation/detail", { id: this.id })
                .then((res) => {
                    this.problem = res.data;
                    this.problemList = [];
                    this.problemList = [
                        ...this.problemList,
                        ...res.data.test_list?.multiple,
                        ...res.data.test_list?.judge,
                        ...res.data.test_list?.completion,
                        ...res.data.test_list?.single,
                    ];
                    this.subData = [];
                    this.problemList.forEach((i) => {
                        this.subData.push({ id: i.id, answer: "", checked: [] });
                    });
                });
        },

        methods: {
            submit() {
                this.subData.forEach((i) => {
                    if (i.checked.length) {
                        i.answer = i.checked.join();
                    }
                });
                this.$https
                    .post("/api/exam/evaluation/submit", {
                        id: this.problem.evaluation.id,
                        data: this.subData,
                    })
                    .then((res) => {
                        if (res.errcode == 1)
                            return this.$message.error("您已提交过问卷");
                        this.show = true;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
.Questionnaire {
    width: 614px;
    margin: 37px auto 157px auto;
    background: white;
    padding: 18px 0 48px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
        font-size: 30px;
        font-weight: 500;
        color: #2d2d2d;
        margin-bottom: 60px;
    }
    .el-divider--horizontal {
        margin-top: 20px;
        margin-bottom: 22px;
    }
    .problem {
        padding-bottom: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        padding: 5px 120px;
        .option {
            display: flex;
            flex-direction: column;
            .option-card {
                margin-bottom: 10px;
                display: flex;
                /deep/.el-radio__label {
                    white-space: pre-wrap;
                }
            }
        }
        /deep/.el-textarea {
            .el-textarea__inner {
                background: #f6f6f6;
                border: 1px solid #d5d5d5;
            }
            .el-input__count {
                background: #f6f6f6;
            }
        }
        .group {
            display: flex;
            flex-direction: column;
            margin-left: 35px;
            .el-checkbox {
                margin-bottom: 5px;
                white-space: pre-wrap;
                display: flex;
                /deep/.el-checkbox__inner {
                    border-radius: 100%;
                }
                /deep/.el-checkbox__inner::after {
                    box-sizing: content-box;
                    content: "";
                    border: 1px solid #fff;
                    height: 2px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    border-radius: 100%;
                    background: #fff;
                    transform: rotate(45deg) scaleY(0);
                    width: 2px;
                }
                /deep/.el-checkbox__input.is-checked
                    .el-checkbox__inner::after {
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }
    }
    .submit {
        width: 166px;
        height: 33px;
        background: linear-gradient(180deg, #ff3764, #ff2604);
        border-radius: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-top: 36px;
    }
}
</style>